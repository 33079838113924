import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Paper,
  Checkbox,
  Container,
  TextField,
  Dialog,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import Btn from "./Components/Btn";
import axios from "axios";
import AvatarIcon from "./Assets/images/avatar.png";
import StraightFace from "./Assets/images/straight-face.png";
import AngleFace from "./Assets/images/angle-face.png";
import AngleFace2 from "./Assets/images/angle-face-2.png";
import faceMaskImg from "./Assets/images/face-mask.png";
import http from "./http";
import GlobalLoading from "./Components/loading";
import GlobalAlert from "./Components/alert";

const EXAMPLE_IMGS = [StraightFace, AngleFace, AngleFace2, AngleFace2];

const defaultFormFields = {
  first_name: "",
  last_name: "",
  member_id: "",
  email: "",
  group_id: "",
  image_s3_path: "",
  category: "",
};

export default function SelfRegistration() {
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [previewImages, setPreviewImages] = React.useState([]);
  const [isAcknowledged, setIsAcknowledged] = React.useState(false);
  const [isCapturingPicture, setIsCapturingPicture] = React.useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [s3Info, setS3Info] = useState(null);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);
  const [category, setCategory] = useState("Member");

  const { first_name, last_name, member_id, group_id, email, image_s3_path } =
    formFields;

  useEffect(() => {
    const { first_name, last_name, group_id } = formFields;
    if (first_name && last_name && group_id) {
      setNextDisabled(false);
    }
  }, [JSON.stringify(formFields)]);

  const handleReset = () => {
    setFormFields(defaultFormFields);
    setS3Info(null);
    setPreviewImages([]);
    setIsAcknowledged(false);
    setNextDisabled(true);
    setNextButtonLoading(false);
    setCategory("Member");
  };

  // 获取上传凭证，url，key
  const handleGetS3Info = async (group_id) => {
    try {
      GlobalLoading.show();
      const res = await http.get(`/customers/presignURL`);
      GlobalLoading.hide();
      console.log("res==>>", res);
      setS3Info(res);
    } catch (e) {
      console.log(e);
      GlobalLoading.hide();
      GlobalAlert.error({
        msg: e.message,
      });
    }
  };

  const handleGoStep2 = async () => {
    await handleGetS3Info(group_id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        first_name: first_name,
        last_name: last_name,
        member_id: member_id ? member_id : undefined,
        group_id: group_id,
        email: email ? email : undefined,
        image_s3_path: image_s3_path,
        category: category,
      };
      GlobalLoading.show();
      const res = await http.post("/customers/", payload);
      GlobalLoading.hide();
      console.log("submit res==>>", res);
      if (res.status_code) {
        GlobalAlert.error({
          msg: res.detail || "Add Failed !",
        });
      } else {
        GlobalAlert.success({
          msg: "Add Success !",
          finish: () => {
            handleReset();
          },
        });
      }
    } catch (err) {
      console.log("submit err", err);
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg:
          err?.response?.data?.detail?.[0]?.msg ||
          err?.response?.data?.detail ||
          err.message,
      });
    }
  };

  const handleTextFieldsChanged = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleImgsUpload = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const uploadedFiles = [...e.target.files].slice(0, 3);
    handleUploadImageToS3(uploadedFiles[0]);
  };

  const handleAddAvatar = (file) => {
    const reader = new FileReader();

    // 文件加载完成时的回调函数
    reader.onload = function (event) {
      // 将文件读取结果作为图片的 src 属性值
      setPreviewImages([{ ...file, src: event.target.result }]);
    };
    // 读取文件内容
    reader.readAsDataURL(file);
  };

  const handleUploadImageToS3 = async (file) => {
    try {
      const payload = new FormData();
      payload.append("Content-Type", s3Info.fields["Content-Type"]);
      payload.append("key", s3Info.fields["key"]);
      payload.append("AWSAccessKeyId", s3Info.fields["AWSAccessKeyId"]);
      payload.append(
        "x-amz-security-token",
        s3Info.fields["x-amz-security-token"]
      );
      payload.append("policy", s3Info.fields["policy"]);
      payload.append("signature", s3Info.fields["signature"]);
      payload.append("file", file);
      handleAddAvatar(file);
      if (s3Info && s3Info.url) {
        GlobalLoading.show();
        setUploadBtnDisabled(true);
        const res = await axios.post(s3Info.url, payload, {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        });
        setUploadBtnDisabled(false);
        GlobalLoading.hide();
        GlobalAlert.success({
          msg: "Upload Success !",
          duration: 6000,
        });
        setFormFields({
          ...formFields,
          image_s3_path: s3Info.fields["key"],
        });
      }
    } catch (e) {
      setUploadBtnDisabled(false);
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg: "Upload Error !",
      });
    }
  };

  const pictureCaptured = (img) => {
    console.log("img==>>", img);
    setIsCapturingPicture(false);
    handleUploadImageToS3(img);
  };

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Stack>
              <div style={{ width: "100%" }}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ mb: 3, mt: 5, mx: "auto" }}
                >
                  Step 1: Add Information
                </Typography>
                <Grid container spacing={5}>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="First name"
                      value={first_name}
                      onChange={handleTextFieldsChanged}
                      name="first_name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Last name"
                      value={last_name}
                      onChange={handleTextFieldsChanged}
                      name="last_name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Club Id"
                      value={group_id}
                      onChange={handleTextFieldsChanged}
                      name="group_id"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Autocomplete
                      value={category}
                      options={[
                        "Member",
                        "Guest",
                        "Not allowed",
                        "Test",
                        "Other1",
                        "Other2",
                      ]}
                      onChange={(e, val) => setCategory(val)}
                      renderInput={(params) => (
                        <TextField label="Category" {...params} fullWidth />
                      )}
                      autoFocus
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Email"
                      value={email}
                      onChange={handleTextFieldsChanged}
                      name="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Member Id"
                      value={member_id}
                      onChange={handleTextFieldsChanged}
                      name="member_id"
                      fullWidth
                      helperText="Only required for gym"
                    />
                  </Grid>
                </Grid>

                <div style={{ margin: "32px auto" }}>
                  <Btn
                    sx={{ width: 200 }}
                    variant="contained"
                    disabled={nextDisabled}
                    loading={nextButtonLoading}
                    onClick={handleGoStep2}
                  >
                    Next
                  </Btn>
                </div>
              </div>

              {s3Info && (
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mb: 3, mt: 2, mx: "auto" }}
                    style={{ marginTop: 50 }}
                  >
                    Step 2: Take Selfie
                  </Typography>
                  <Paper
                    elevation={0}
                    style={{
                      background: "#FCFCFC",
                      border: "dashed 2px #727272",
                      padding: 20,
                    }}
                  >
                    <Grid
                      container
                      spacing={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item lg={4} xs={12}>
                        <Stack alignItems="center">
                          <img
                            src={
                              previewImages.length > 0
                                ? previewImages[0]?.src
                                : AvatarIcon
                            }
                            alt=""
                            style={{
                              width: 200,
                              height: 200,
                              background: "#ddd",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                            <Btn
                              variant="contained"
                              component="label"
                              disabled={uploadBtnDisabled}
                            >
                              Upload
                              <input
                                type="file"
                                onChange={handleImgsUpload}
                                accept="image/*"
                                hidden
                              />
                            </Btn>
                            <Btn
                              variant="contained"
                              onClick={() => setIsCapturingPicture(true)}
                              disabled={uploadBtnDisabled}
                            >
                              Capture
                            </Btn>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Stack alignItems="center">
                          <img
                            style={{
                              width: 200,
                              height: 200,
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src={EXAMPLE_IMGS[0] /*[currPictureIndex]*/}
                            alt=""
                          />
                          <p
                            align="center"
                            style={{
                              color: "#6C6C6C",
                              maxWidth: 300,
                              fontSize: 15,
                            }}
                          >
                            Example
                          </p>
                          {/* <Btn variant="contained">Save</Btn> */}
                        </Stack>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <p style={{ textAlign: "justify" }}>
                          Tips: Make sure there is good lighting, your face
                          should cover 90% of the circle, don't wear blocking
                          objects i.e glasses, hat etc.
                        </p>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mb: 3, mt: 5, mx: "auto" }}
                  >
                    Step 3: Check box & Submit
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAcknowledged}
                        onChange={(e) => setIsAcknowledged(e.target.checked)}
                      />
                    }
                    label="By submitting this form, you are acknowledging and consenting for your image to be used for facial recognition and check-in purposes. Your personal information will not be shared with any outside organizations."
                    sx={{
                      mt: 5,
                      span: {
                        fontSize: 15,
                        color: "#6C6C6C",
                      },
                    }}
                  />

                  <div style={{ margin: "32px auto" }}>
                    <Btn
                      sx={{ width: 200 }}
                      variant="contained"
                      type="submit"
                      disabled={!isAcknowledged}
                    >
                      Submit
                    </Btn>
                  </div>
                </div>
              )}
            </Stack>
          </form>
        </Grid>
      </Grid>

      <Dialog
        sx={{ bgcolor: "transparent" }}
        open={isCapturingPicture}
        onClose={() => setIsCapturingPicture(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <TakePicture pictureCaptured={pictureCaptured} />
      </Dialog>
    </Container>
  );
}

function TakePicture({ pictureCaptured }) {
  const [capturedImg, setCapturedImg] = React.useState(null);
  const stream = React.useRef();
  const videoRef = React.useRef();
  const canvasRef = React.useRef();
  const WIDTH = 400;
  const HEIGHT = 300;

  React.useEffect(() => {
    (async () => {
      const cameras = await navigator.mediaDevices.enumerateDevices();
      if (cameras.length === 0) return alert("No cameras detected");

      const st = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { deviceId: cameras[0].deviceId },
      });
      videoRef.current.srcObject = st;
      videoRef.current.play();
      stream.current = st;
    })();

    return () => {
      stream.current?.getTracks().forEach((t) => t.stop());
    };
  }, []);

  const takePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, WIDTH, HEIGHT);
    canvasRef.current.toBlob((file) => {
      setCapturedImg(file);
    });
  };

  const handleSubmitPicture = () => {
    const img = new File([capturedImg], Math.random().toString(36) + ".jpg");
    pictureCaptured(img);
  };

  return (
    //  <div >
    <Stack
      spacing={1}
      sx={{ m: 2 }}
      style={{
        position: "fixed",
        top: "7%",
        left: "50%",
        transform: "translateX(-50%)",
        background: "white",
        padding: "8px",
      }}
    >
      <div style={{ position: "relative" }}>
        <video
          style={{ display: capturedImg ? "none" : "block" }}
          ref={videoRef}
          width={WIDTH}
          height={HEIGHT}
        />
        <img
          src={faceMaskImg}
          alt=""
          style={{
            position: "absolute",
            width: "400px",
            height: "300px",
            left: 0,
            top: 0,
          }}
        />
      </div>

      {!capturedImg && (
        <Btn variant="contained" fullWidth onClick={takePhoto}>
          Take picture
        </Btn>
      )}

      {capturedImg && (
        <>
          <img
            src={URL.createObjectURL(capturedImg)}
            width={WIDTH}
            height={HEIGHT}
          />
          <Stack direction="row" spacing={1}>
            <Btn variant="contained" onClick={handleSubmitPicture} fullWidth>
              Keep picture
            </Btn>
            <Btn
              variant="contained"
              onClick={() => setCapturedImg(null)}
              fullWidth
            >
              Take another
            </Btn>
          </Stack>
        </>
      )}
      <canvas
        width={WIDTH}
        height={HEIGHT}
        ref={canvasRef}
        style={{ display: "none" }}
      />
    </Stack>
    //  </div>
  );
}
// spacing={1} sx={{ m: 2 }}
