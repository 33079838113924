import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Assets/images/logo.png";
import {
  getUserInfo,
  getUserRole,
  removeToken,
  removeUserInfo,
  removeUserRole,
} from "./authUtils";

export default function MainAppBar() {
  const currentUser = getUserInfo();
  const userRole = getUserRole();

  const handleLogout = () => {
    removeUserInfo();
    removeUserRole();
    removeToken();
    window.location.href = "/";
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ background: "#0B0B45", overflow: "hidden" }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img
                src={Logo}
                alt="GUEST VISION"
                style={{ width: "200px", marginTop: "8px" }}
              />
            </Link>
          </Typography>

          {currentUser && (userRole === "admin" || userRole === "superadmin") && (
            <>
              <Link to="/customer">
                <Button color="inherit">Check-in</Button>
              </Link>
              <Link to="/history">
                <Button color="inherit">Check-in History</Button>
              </Link>
              <Link to="/self-registration">
                <Button color="inherit">Self Registration</Button>
              </Link>
              <Link to="/admin">
                <Button color="inherit">Admin</Button>
              </Link>
            </>
          )}

          {currentUser && userRole === "staff" && (
            <>
              <Link to="/customer">
                <Button color="inherit">Check-in</Button>
              </Link>
              <Link to="/history">
                <Button color="inherit">Check-in History</Button>
              </Link>
              <Link to="/self-registration">
                <Button color="inherit">Self Registration</Button>
              </Link>
            </>
          )}

          {currentUser ? (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          ) : (
            <>
              <Button color="inherit">
                <Link to="/login">Login</Link>
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
