import React from "react";
import { createRoot } from "react-dom/client";
import { Alert } from "@mui/material";

const CustomAlert = (props) => {
  const { options = {} } = props;
  const { type, msg } = options;
  return (
    <div
      style={{
        position: "fixed",
        height: 50,
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <Alert severity={type}>{msg}</Alert>
    </div>
  );
};

const removeDom = (domClass) => {
  const loadingContainers = document.querySelectorAll(`.${domClass}`);
  loadingContainers.forEach((container) => {
    container.parentNode.removeChild(container);
  });
};

const fn = () => {};

const GlobalAlert = {
  show: function (options = {}) {
    const { duration = 5000, finish = fn } = options;
    const container = document.createElement("div");
    const domClass = `customer-alert-container_${new Date().getTime()}`;
    container.classList.add(domClass); // 添加类名
    document.body.appendChild(container);
    createRoot(container).render(<CustomAlert options={options} />);
    setTimeout(() => {
      removeDom(domClass);
      if (finish) {
        finish();
      }
    }, duration);
  },
  success: function (options = {}) {
    options.type = "success";
    const { duration = 5000, finish = fn } = options;
    const container = document.createElement("div");
    const domClass = `customer-alert-container_${new Date().getTime()}`;
    container.classList.add(domClass); // 添加类名
    document.body.appendChild(container);
    createRoot(container).render(<CustomAlert options={options} />);
    setTimeout(() => {
      removeDom(domClass);
      if (finish) {
        finish();
      }
    }, duration);
  },
  error: function (options = {}) {
    options.type = "error";
    const { duration = 5000, finish = fn } = options;
    const container = document.createElement("div");
    const domClass = `customer-alert-container_${new Date().getTime()}`;
    container.classList.add(domClass); // 添加类名
    document.body.appendChild(container);
    createRoot(container).render(<CustomAlert options={options} />);
    setTimeout(() => {
      removeDom(domClass);
      if (finish) {
        finish();
      }
    }, duration);
  },
};

export default GlobalAlert;
